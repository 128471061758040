const { NODE_ENV, REACT_APP_ENV } = process.env

export const isDevelopmentEnv = () => {
    return REACT_APP_ENV === 'dev'
}
export const isProductionEnv = () => {
    return REACT_APP_ENV === 'production'
}
export const isDevStagingEnv = () => {
    return NODE_ENV === 'dev' && REACT_APP_ENV === 'stg'
}
export const isNodeEnvProduction = () => {
    return NODE_ENV === 'production'
}
export function isStaging() {
    return REACT_APP_ENV === 'stg'
}
