import React, {useEffect, useState} from 'react';
import {useGlobal} from "reactn"
import MenuAppBar from "../../MenuAppBar/MenuAppBar";
import Footer from "../../Footer/Footer";
import PropTypes from 'prop-types';
import {Person} from '@material-ui/icons';
import {
    AppBar,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    IconButton,
    Toolbar,
    Typography,
    withStyles,
} from '@material-ui/core';
import './statics/css/Payment.css';
import classNames from 'classnames';
import IcnAvatart from './statics/img/icn-sample.png';
import IcnSample from './statics/img/icn-sample.png';
import IcnTicket from './statics/img/icn-ticket-icon.png';
import IcnPoint from './statics/img/icn-point-text.png';
import IcnBankTransfer from './statics/img/transferBanking.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios'
import visaImg from './statics/img/visa_electron.png'
import mastercardImg from './statics/img/mastercard.png'
import jcbImg from './statics/img/jcb.png'
import american_expressImg from './statics/img/american_express.png'
import moment from "moment-timezone";
import _ from 'lodash'
// import InfiniteScroll from 'react-infinite-scroller'
import ButtonBase from "@material-ui/core/ButtonBase";
import InfiniteScroll from 'react-infinite-scroll-component';
import {FormattedMessage} from "react-intl";

export const numberFormat = (language, data) => {
    const numberFormated = new Intl.NumberFormat(language, {
        style: 'currency',
        currency: 'JPY',
        currencyDisplay: 'name'
    });
    return numberFormated.format(data)
}


// let lastChunkIndex = 0

function History2({classes}) {

    // const [rows, setRows] = useState([])
    const [rowsGroupByDate, setRowsGroupByDate] = useState({})
    const [loading, setLoading] = useState(true)
    // const [purchaseDateList, setPurchaseDateList] = useState([])
    const [purchaseDateListChunked, setPurchaseDateListChunked] = useState([])
    const [lastChunkIndex, setLastChunkIndex] = useState(0)

    useEffect(() => {
        // console.log('mount')
        axios.get('/payment/history/purchase-date-list')
            .then(res => {
                setLoading(false)
                const orderValues = _.orderBy(res.data, "purchase_date_utc", "desc")
                setPurchaseDateListChunked(_.chunk(orderValues, 10))
            })
            .catch(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        if (!_.isEmpty(purchaseDateListChunked)) {
            requestPurchases()
        }
    }, [purchaseDateListChunked])

    const handleScroll = (e) => {
        const {scrollHeight, scrollTop, clientHeight} = e.target.scrollingElement
        const bottom = scrollHeight - scrollTop - clientHeight < 1000
        if (bottom && !loading) {
            requestPurchases()
        }
    }

    const requestPurchases = async () => {
        const chunk = purchaseDateListChunked[lastChunkIndex]
        if (!chunk) return
        setLoading(true)
        try {
            const toDate = chunk[0].purchase_date_utc
            const fromDate = chunk[chunk.length - 1].purchase_date_utc
            const {data: {rows}} = await axios.get(`/payment/history`, {
                params: {fromDate, toDate}
            })
            let newRowsGroupByDate = _.groupBy(_.orderBy(rows, "purchase_date_utc", "desc"), r => moment(r['purchase_date_utc']).format('YYYY-MM-DD HH:mm:ss'))
            // console.log(newRowsGroupByDate);
            // setRows(rows)
            setRowsGroupByDate({...rowsGroupByDate, ...newRowsGroupByDate})

            setLastChunkIndex(lastChunkIndex + 1)
            setLoading(false)

        } catch (e) {
            setLoading(false)
        } finally {
        }
    }
    return (
        <div className={classes.root}>
            <MenuAppBar/>
            <div className={classes.pc_payment}>
                <Grid container>
                    <Grid item xs={12}>
                        <AppBar className={classes.paymentTitle} position="static">
                            <Toolbar>
                                <IconButton className={classes.unitIcn} color="inherit" aria-label="Menu">
                                    <Person/>
                                </IconButton>
                                <Typography variant="title" color="inherit">
                                    <FormattedMessage id={"home.userSetting"}/>
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Card style={{padding: 15, background: "rgb(220, 229, 238)"}} className={classes.Detail}>
                            <div className={classes.unitTitle}>
                                <h1>
                                    <FormattedMessage id={"home.purchaseHistory"}/>
                                </h1>
                            </div>

                            <Grid container justify={"center"}>
                                <Typography
                                    color={"secondary"}>
                                    {!loading && !purchaseDateListChunked.length ?
                                        <FormattedMessage id={"home.noData"}/> : ""}
                                </Typography>
                            </Grid>
                            <InfiniteScroll
                                dataLength={purchaseDateListChunked.length}
                                hasMore={true}
                                next={requestPurchases}
                                onScroll={handleScroll}
                            >
                                {
                                    Object.keys(rowsGroupByDate).map((date) => {
                                        const purchases = rowsGroupByDate[date]
                                        if (!purchases.length) return null;
                                        if (!purchases[0].banktransfer) {
                                            const total = purchases[0].stripe_charge_info ? purchases[0].stripe_charge_info.amount : 0;
                                            let subtotal = 0, deliveryCharge = 0, fee = 0
                                            purchases.forEach(purchase => {
                                                const {unit_price, number, delivery_charge, divided_fee} = purchase
                                                subtotal += unit_price * number
                                                deliveryCharge += parseInt(delivery_charge)
                                                fee += divided_fee
                                            })

                                            return (
                                                <CardContent
                                                    style={{
                                                        marginBottom: 15,
                                                        borderRadius: 10,
                                                        backgroundColor: "#ffffff"
                                                    }}
                                                    key={date}>
                                                    <PurchaseHeader classes={classes} date={date}/>
                                                    <div className={classes.paymentMethod}>
                                                        <div className={classNames(classes.setFlex, classes.setMB10)}>

                                                            <PaymentInfo classes={classes}
                                                                         purchases={purchases}
                                                                         total={total}
                                                                         subtotal={subtotal}
                                                                         deliveryCharge={deliveryCharge}
                                                                         fee={fee}
                                                            />


                                                            <hr style={{width: '90%', margin: '20px auto'}}/>
                                                            <PurchaseItemList purchases={purchases} classes={classes}/>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            )
                                        }
                                        // bank transfer

                                        return (
                                            <CardContent
                                                style={{marginBottom: 15, borderRadius: 10, backgroundColor: "#ffffff"}}
                                                key={date}>
                                                <PurchaseHeader classes={classes} date={date}/>
                                                <div className={classes.paymentMethod}>
                                                    <div className={classNames(classes.setFlex, classes.setMB10)}>
                                                        <PaymentInfo classes={classes}
                                                                     purchases={purchases}
                                                                     total={purchases[0].prices || 0}
                                                                     subtotal={(purchases[0].prices || 0) / 1.1}
                                                                     deliveryCharge={0}
                                                                     fee={0}
                                                                     banktransfer={true}
                                                        />


                                                        <hr style={{width: '90%', margin: '20px auto'}}/>
                                                        <BankTransferItemList purchases={purchases[0]}
                                                                              classes={classes}/>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        )

                                    })
                                }
                            </InfiniteScroll>

                            {loading &&
                            <Grid container justify={"center"}><CircularProgress/></Grid>}
                        </Card>
                    </Grid>
                </Grid>

            </div>

            <Footer/>
        </div>

    );

}

function CardBrandImg({brand}) {
    switch (brand) {
        case 'Visa':
            return <img src={visaImg} alt="visa"/>
        case 'MasterCard':
            return <img src={mastercardImg} alt="master"/>
        case 'JCB':
            return <img src={jcbImg} alt="jcb"/>
        case 'American Express':
            return <img src={american_expressImg} alt="amex"/>
        default:
            break
    }
}

// function toLocalDateTime(date) {
//     // console.log(date)
//     const utc = moment.tz(date, 'UTC')
//     return utc.clone().tz(moment.tz.guess() || 'Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')
// }

function PurchaseHeader({classes, date}) {
    return (
        <AppBar className={classes.paymentTitle} position="static">
            <Toolbar>
                <Typography variant="title" color="inherit">
                    <FormattedMessage id={"history.settlementDate"}/> {date}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

function PaymentMethod({classes, purchases, banktransfer}) {
    return (
        <Grid item xs={6}>
            <Typography variant='h5'
                        component='b'
                        className={classNames(classes.setMT20, classes.setMB20, classes.setML15)}>
                <FormattedMessage id={"history.paymentMethod"}/>
            </Typography>
            <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="stretch"
                style={{height: '80%'}}
            >
                <Grid container
                      alignItems={"center"}
                      spacing={8}>
                    <Grid item>
                        {purchases[0].stripe_charge_info &&
                        <CardBrandImg
                            brand={purchases[0].stripe_charge_info.source.brand}
                        />
                        }
                        {banktransfer &&
                        <img src={IcnBankTransfer} alt={''} style={{width: "50%"}}/>
                        }

                    </Grid>
                    <Grid item>
                        <Typography>
                            {purchases[0].stripe_charge_info ? purchases[0].stripe_charge_info.source.last4 : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    {purchases[0].stripe_charge_info &&
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {
                            if (purchases[0].stripe_charge_info)
                                window.open(purchases[0].stripe_charge_info.receipt_url, "_blank")
                        }}>
                        <FormattedMessage id={"history.issuanceOfReceipt"}/>
                    </Button>
                    }

                </Grid>
            </Grid>
        </Grid>
    )
}

function PaymentTotal({classes, total, subtotal, deliveryCharge, purchases, fee}) {
    const [language] = useGlobal("language")
    const divided = isDivided(purchases)
    const is_discount = isDiscount(purchases)
    const tax = (is_discount || divided) ? Math.floor(subtotal * 0.1) : (total - subtotal || 0 - deliveryCharge || 0)
    const discount = is_discount ? (subtotal + tax + fee - total) : 0
    return (
        <Grid item xs={6}>
            <Typography variant='h5' component='b'
                        className={classNames(classes.setMT20, classes.setMB20, classes.setML15)}>
                <FormattedMessage id={"history.orderDetails"}/>
            </Typography>
            <Table padding='none'
                // style={{ width: '80%', marginLeft: '15px' }}
            >
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.cell} padding={"none"}>
                            <Typography>
                                <FormattedMessage id={"history.subTotal"}/>
                            </Typography>
                        </TableCell>
                        <TableCell align={"right"}
                                   padding={"none"}
                                   className={classes.cell}>
                            {numberFormat(language, subtotal)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.cell}>
                            <Typography>
                                <FormattedMessage id={"history.tax"}/>
                            </Typography>
                        </TableCell>
                        <TableCell align={"right"}
                                   className={classes.cell}>
                            {numberFormat(language, tax)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.cell}>
                            <Typography>
                                <FormattedMessage id={"history.deliveryChange"}/>
                            </Typography>
                        </TableCell>
                        <TableCell align={"right"}
                                   className={classes.cell}>
                            {numberFormat(language, deliveryCharge || 0)}
                        </TableCell>
                    </TableRow>
                    {
                        divided &&
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <Typography>
                                    <FormattedMessage id={"history.monthlyInstallmentFee"}/>
                                </Typography>
                            </TableCell>
                            <TableCell numeric className={classes.cell}>
                                {numberFormat(language, fee)}
                            </TableCell>
                        </TableRow>
                    }
                    {
                        is_discount &&
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <Typography>
                                    <FormattedMessage id={"history.discount"}/>
                                </Typography>
                            </TableCell>
                            <TableCell numeric className={classes.cell} align={"right"}>
                                {numberFormat(language, discount)}
                            </TableCell>
                        </TableRow>
                    }

                    <TableRow>
                        <TableCell className={classes.cell}>
                            <Typography>
                                <FormattedMessage id={"history.invoiceAmount"}/>
                            </Typography>
                        </TableCell>
                        <TableCell align={"right"}
                                   className={classes.cell}>
                            <Typography>
                                {numberFormat(language, total)}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Grid>
    )
}

function PaymentInfo({classes, purchases, total, subtotal, deliveryCharge, fee, banktransfer = false}) {
    return (
        <Grid container>
            <PaymentMethod classes={classes} purchases={purchases} banktransfer={banktransfer}/>
            <PaymentTotal classes={classes} total={total} subtotal={subtotal} deliveryCharge={deliveryCharge} fee={fee}
                          purchases={purchases}/>
        </Grid>
    )
}

const handleClickButton = (url) => () => window.location.href = url;

function PurchaseItemList({purchases, classes}) {
    const [language] = useGlobal('language')
    const coupons_code_str = purchases[0] && purchases[0].coupons_code ? purchases[0].coupons_code : "[]"
    const codeList = JSON.parse(coupons_code_str)
    return (
        <div
            // style={{ paddingLeft: '30px' }}
        >
            {purchases.map((purchase, idx) => {
                const {
                    product_info,
                    package_info,
                    enecolor_book_diagnosis_url,
                    paid_question_process,
                    unit_price,
                    product_id,
                    // stripe_charge_info
                } = purchase

                return (
                    <div key={idx}>
                        <Grid container alignItems={"center"}>
                            <Grid item
                                // style={{ margin: 'auto 0px' }}
                            >
                                {//!isMobileOnly ?
                                    <ButtonBase className={classes.image}>
                                        <img className={classes.img} alt="complex" src={IcnSample}/>
                                    </ButtonBase>
                                    // <Avatar alt='avatar'
                                    //         src={IcnSample}/>
                                    //: ""
                                }
                            </Grid>
                            <Grid item xs sm md container direction={"column"}>
                                <Typography variant="subtitle1">
                                    {product_info ? product_info.name : package_info ? package_info.packageName : product_id}
                                </Typography>
                                <Typography variant="caption">
                                    {product_info ? product_info.remarks : null}
                                </Typography>
                                <Typography gutterBottom color='secondary'>
                                    {numberFormat(language, unit_price)} x {purchase.number}
                                </Typography>
                                {paid_question_process ? (
                                        paid_question_process.done_flg === '1' ?
                                            <Typography variant="body1">
                                                <FormattedMessage id={"history.diagnosed"}/>
                                            </Typography>
                                            :
                                            <div>
                                                <Typography variant="body1" color="secondary">
                                                    <FormattedMessage id={"history.notification"}/>
                                                </Typography>
                                                <Button variant="contained" className="btnPrimary"
                                                        onClick={handleClickButton(enecolor_book_diagnosis_url)}>
                                                    <FormattedMessage id={"history.makeADiagnosis"}/>
                                                </Button>
                                            </div>
                                    )
                                    : ""
                                }
                            </Grid>
                        </Grid>
                    </div>
                )
            })}
            {codeList.length !== 0 &&
            <div>
                <hr style={{width: '90%', margin: '20px auto'}}/>
                <Typography>
                    <FormattedMessage id={"history.coupon"}/>
                </Typography>
                <ul>
                    {codeList.map(code => {
                        return <li>{code}</li>
                    })}
                </ul>
            </div>
            }


        </div>
    )
}

function BankTransferItemList({purchases, classes}) {
    const [language] = useGlobal('language')
    const codeList = purchases?.coupons || []
    console.log({purchases});
    return (
        <div
            // style={{ paddingLeft: '30px' }}
        >
            {purchases.packagesInfo.map((pkg, idx) => {
                return (
                    <div key={idx}>
                        <Grid container alignItems={"center"}>
                            <Grid item
                                // style={{ margin: 'auto 0px' }}
                            >
                                {//!isMobileOnly ?
                                    <ButtonBase className={classes.image}>
                                        <img className={classes.img} alt="complex" src={IcnSample}/>
                                    </ButtonBase>
                                    // <Avatar alt='avatar'
                                    //         src={IcnSample}/>
                                    //: ""
                                }
                            </Grid>
                            <Grid item xs sm md container direction={"column"}>
                                <Typography variant="subtitle1">
                                    {pkg.name}
                                </Typography>
                                {/*<Typography variant="caption">*/}
                                {/*    {product_info ? product_info.remarks : null}*/}
                                {/*</Typography>*/}
                                <Typography gutterBottom color='secondary'>
                                    {numberFormat(language, pkg.beforetax)} x {pkg.num}
                                </Typography>
                                {/*{paid_question_process ? (*/}
                                {/*        paid_question_process.done_flg === '1' ?*/}
                                {/*            <Typography variant="body1">*/}
                                {/*                <FormattedMessage id={"history.diagnosed"}/>*/}
                                {/*            </Typography>*/}
                                {/*            :*/}
                                {/*            <div>*/}
                                {/*                <Typography variant="body1" color="secondary">*/}
                                {/*                    <FormattedMessage id={"history.notification"}/>*/}
                                {/*                </Typography>*/}
                                {/*                <Button variant="contained" className="btnPrimary"*/}
                                {/*                        onClick={handleClickButton(enecolor_book_diagnosis_url)}>*/}
                                {/*                    <FormattedMessage id={"history.makeADiagnosis"}/>*/}
                                {/*                </Button>*/}
                                {/*            </div>*/}
                                {/*    )*/}
                                {/*    : ""*/}
                                {/*}*/}
                            </Grid>
                        </Grid>
                    </div>
                )
            })}
            {purchases.products?.map((prod, idx) => {
                const cartIdx = _.findIndex(purchases.cart || [], {product_id: prod.product_id})
                let num
                if (cartIdx !== -1) {
                    num = purchases.cart[cartIdx].num
                }
                return (
                    <div key={idx}>
                        <Grid container alignItems={"center"}>
                            <Grid item
                                // style={{ margin: 'auto 0px' }}
                            >
                                {//!isMobileOnly ?
                                    <ButtonBase className={classes.image}>
                                        <img className={classes.img} alt="complex" src={IcnSample}/>
                                    </ButtonBase>
                                    // <Avatar alt='avatar'
                                    //         src={IcnSample}/>
                                    //: ""
                                }
                            </Grid>
                            <Grid item xs sm md container direction={"column"}>
                                <Typography variant="subtitle1">
                                    {prod.name}
                                </Typography>
                                <Typography variant="caption">
                                    {prod.remarks ? prod.remarks : null}
                                </Typography>
                                <Typography gutterBottom color='secondary'>
                                    {numberFormat(language, prod.unit_price)} {num ? `x ${num}` : ''}
                                </Typography>
                                {/*{paid_question_process ? (*/}
                                {/*        paid_question_process.done_flg === '1' ?*/}
                                {/*            <Typography variant="body1">*/}
                                {/*                <FormattedMessage id={"history.diagnosed"}/>*/}
                                {/*            </Typography>*/}
                                {/*            :*/}
                                {/*            <div>*/}
                                {/*                <Typography variant="body1" color="secondary">*/}
                                {/*                    <FormattedMessage id={"history.notification"}/>*/}
                                {/*                </Typography>*/}
                                {/*                <Button variant="contained" className="btnPrimary"*/}
                                {/*                        onClick={handleClickButton(enecolor_book_diagnosis_url)}>*/}
                                {/*                    <FormattedMessage id={"history.makeADiagnosis"}/>*/}
                                {/*                </Button>*/}
                                {/*            </div>*/}
                                {/*    )*/}
                                {/*    : ""*/}
                                {/*}*/}
                            </Grid>
                        </Grid>
                    </div>
                )
            })}
            {codeList.length !== 0 &&
            <div>
                <hr style={{width: '90%', margin: '20px auto'}}/>
                <Typography>
                    <FormattedMessage id={"history.coupon"}/>
                </Typography>
                <ul>
                    {codeList.map(code => {
                        return <li>{code}</li>
                    })}
                </ul>
            </div>
            }


        </div>
    )
}

History2.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({

    image: {
        width: 50,
        height: 50,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    root: {
        flexGrow: 1,
    },
    setFlex: {
        dispay: "flex",
        flexWrap: "wrap",
    },
    setFlexBasis10: {
        flexBasis: "10%",
    },
    setFlexBasis20: {
        flexBasis: "20%",
    },
    setFlexBasis30: {
        flexBasis: "30%",
    },
    setFlexBasis40: {
        flexBasis: "40%",
    },
    setFlexBasis50: {
        flexBasis: "50%",
    },
    setFlexBasis60: {
        flexBasis: "60%",
    },
    setFlexBasis70: {
        flexBasis: "70%",
    },
    setFlexBasis80: {
        flexBasis: "80%",
    },
    setFlexBasis85: {
        flexBasis: "85%",
    },
    setMB10: {
        marginBottom: "10px",
    },
    setMB20: {
        marginBottom: "20px",
    },
    setMB25: {
        marginBottom: "25px",
    },
    setMB30: {
        marginBottom: "30px",
    },
    setMB35: {
        marginBottom: "35px",
    },
    setMT20: {
        marginTop: "20px"
    },
    setML15: {
        marginLeft: "15px"
    },
    pc_payment: {
        display: "block",
        width: "90vw",
        maxWidth: "675px",
        margin: "0 auto",
        marginTop: "30px",
    },

    // For bar title
    paymentTitle: {
        background: "#3a3a3a",
    },
    unitIcn: {
        marginRight: "20px",
        border: "1px solid",
    },

    // For sub title of class Detail
    Detail: {
        marginTop: "20px",
        border: "5px solid #3a3a3a",
        borderRadius: "10px",
    },
    unitTitle: {
        "& h1": {
            // paddingTop: "18px",
            // paddingBottom: "22px",
            fontSize: "18pt",
            fontWeight: "bold",
            textAlign: "center",
        },
        "& h2": {
            fontSize: "16pt",
            lineHeight: "28pt",
            fontWeight: "bold",
        },
    },
    paymentHistory: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "20px",
    },
    historyTitle: {
        [theme.breakpoints.down('sm')]: {
            flexBasis: "60%",
        },
        [theme.breakpoints.down('xs')]: {
            flexBasis: "40%",
        },
    },
    historyViewHistory: {
        [theme.breakpoints.down('sm')]: {
            flexBasis: "40%",
        },
        [theme.breakpoints.down('xs')]: {
            flexBasis: "60%",
        }
    },
    unitAction: {
        textAlign: "right",
        "& a": {
            color: "#fff",
            textDecoration: "none",
        }
    },

    // For payment method
    paymentMethod: {},
    unitPanelCard: {
        boxShadow: "none",
        background: "#f5f5f5",
        borderRadius: "10px !important"
    },
    unitMethodSummary: {},
    unitPanelTitle: {
        // paddingLeft: "60px",
        // backgroundImage: "url(" + IcnVisa + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
        fontSize: "13pt",
        lineHeight: "28pt",
        [theme.breakpoints.down("xs")]: {
            display: "block",
        },
    },
    unitPanelExpire: {
        fontSize: "13pt",
        lineHeight: "28pt",
        [theme.breakpoints.down("xs")]: {
            display: "block",
            paddingLeft: "60px",
        },
    },
    unitPanelInfo: {
        width: "90%",
        margin: "0 auto",
        marginBottom: "15px",
        padding: "20px",
        background: "#fff",
        borderRadius: "10px !important",
    },
    unitCardAction: {
        marginTop: "62px",
    },
    unitAddCard: {
        textAlign: "right",
        fontSize: "12pt",
        lineHeight: "16pt",
    },

    // For Registered
    unitPay: {
        marginBottom: "15px",
        padding: "20px",
        background: "#f5f5f5",
        borderRadius: "8px",
    },
    unitPayContent: {
        display: "flex",
        flexWrap: "wrap",
        borderBottom: "1px solid #b7b7b7",
    },
    unitPayInfo: {
        flexBasis: "48%",
        marginRight: "4%",
        [theme.breakpoints.down("xs")]: {
            flexBasis: "100%",
            marginRight: "0",
        }
    },
    unitPayDetail: {
        flexBasis: "48%",
        [theme.breakpoints.down("xs")]: {
            flexBasis: "100%",
        }
    },
    unitPayTitle: {
        "& h2": {
            fontSize: "15pt",
            lineHeight: "24pt",
            backgroundImage: "url(" + IcnAvatart + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left",
            paddingLeft: "2em",
        },

    },
    unitPayAction: {
        display: "flex",
        flexWrap: "wrap",
        "&dl": {
            marginBottom: "0",
        },
        "& dt": {
            flexBasis: "60%",
            margin: "0",
            [theme.breakpoints.down("xs")]: {
                flexBasis: "100%",
            },
        },
        "& dd": {
            flexBasis: "40%",
            margin: "0",
            textAlign: "right",
            "& >span>span ": {
                height: "14px",
            },
            [theme.breakpoints.down("xs")]: {
                flexBasis: "100%",
            },
        },
    },

    btnCat: {
        marginRight: "10px",
        color: "#fff",
        background: "#a2a2a2",
    },
    btnChange: {
        marginLeft: "-8px",
        color: "#4fa4e0",
    },
    btnStatus: {
        marginRight: "10px",
        background: "#3a3a3a",
        color: "#fff",

    },
    unitEndExpire: {
        color: "#e93c3c",
    },

    // For Point
    unitPoint: {
        marginBottom: "15px",
        paddingRight: "20px",
        paddingTop: "0",
        paddingLeft: "20px",
        background: "#f5f5f5",
        borderRadius: "8px",
    },
    unitPointContent: {
        display: "flex",
        flexWrap: "wrap",
    },
    unitPointInfo: {
        [theme.breakpoints.down("xs")]: {
            flexBasis: "100%",
        }
    },
    unitPointDetail: {},
    unitPointTitle: {
        "& h2": {
            fontSize: "15pt",
            lineHeight: "24pt",
            backgroundImage: "url(" + IcnAvatart + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left",
            paddingLeft: "2em",
        }
    },
    unitPointNumber: {
        "& p": {
            marginTop: "22px",
            paddingLeft: "30px",
            backgroundImage: "url(" + IcnPoint + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left",
        },
        [theme.breakpoints.down("xs")]: {
            flexBasis: "50%",
            marginBottom: "20px",
        }
    },
    unitPointAction: {
        textAlign: "right",
        [theme.breakpoints.down("xs")]: {
            flexBasis: "50%",
            marginBottom: "20px",
        }
    },
    unitCharge: {
        marginTop: "15px",
    },
    // For ticket
    // For Point
    unitTicket: {
        marginBottom: "15px",
        paddingRight: "20px",
        paddingTop: "0",
        paddingLeft: "20px",
        background: "#f5f5f5",
        borderRadius: "8px",
    },
    unitTicketContent: {
        display: "flex",
        flexWrap: "wrap",
    },
    unitTicketInfo: {
        [theme.breakpoints.down("xs")]: {
            flexBasis: "100%",
        }
    },
    unitTicketDetail: {},
    unitTicketTitle: {
        "& h2": {
            fontSize: "15pt",
            lineHeight: "24pt",
            backgroundImage: "url(" + IcnAvatart + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left",
            paddingLeft: "2em",
        }
    },
    unitTicketNumber: {
        "& p": {
            marginTop: "16px",
            paddingLeft: "30px",
            backgroundImage: "url(" + IcnTicket + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left",
            lineHeight: "30px",
        },
        [theme.breakpoints.down("xs")]: {
            flexBasis: "50%",
            marginBottom: "20px",
        }
    },
    unitTicketAction: {
        textAlign: "right",
        [theme.breakpoints.down("xs")]: {
            flexBasis: "50%",
            marginBottom: "20px",
        }
    },
    cell: {
        borderBottom: 'none'
    },
    pageActive: {
        backgroundColor: 'lightblue'
    },
    LinkClass: {
        color: 'white'
    }

});
const isDivided = purchases => {
    let result = false
    purchases.forEach(purchase => {
        if (purchase.divided) {
            result = true
        }
    })
    return result
}
const isDiscount = purchases => {
    let result = false
    purchases.forEach(purchase => {
        if (purchase.coupons_code && purchase.coupons_code !== "[]") {
            result = true
        }
    })
    return result
}
export default withStyles(styles)(History2);
