import React from 'react';
import {FormattedMessage} from "react-intl";
import {setGlobal, useGlobal} from "reactn";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {isMobileOnly} from "react-device-detect";
import {numberFormat} from "../../Account/Payment/History2";
import moment from "moment";

function DialogPlanManagement({open, setOpen, item}) {
    const [language] = useGlobal("language")
    const onClose = () => {
        setOpen(false)
    }
    const handleDialogUnSub = () => {
        setGlobal({
            openUnsub: item
        }).then()
        setOpen(false)
    }
    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={isMobileOnly ? 'md' : 'sm'}
            onClose={onClose}
        >
            <DialogTitle><FormattedMessage id={'account.planmanagement'}/></DialogTitle>
            <DialogContent>
                <div className={'flex mb-4'}>
                    <img loading="lazy" src={item?.product_data?.images[0] || '/img/geniam.svg'}
                         className={'object-contain w-24 h-24 mr-2'} alt=''/>

                    <div className={"flex flex-col"}>
                        <div>
                            <div
                                className={'text-blue-500 font-bold'}>{item?.plan?.name || item?.product_data?.description}</div>
                            <div
                                className={"text-sm text-[#808080]"}>{item?.description || item?.product_data?.description}</div>
                            <div className={"flex items-center text-sm italic text-[#808080]"}>
                                <span className={"w-[6px] h-[6px] bg-[#808080] rounded-full flex mr-2"}/>
                                <div className={""}>
                                    <FormattedMessage
                                        id={'subscription.priceincludingtax'}/>: {numberFormat(language, item?.plan?.amount)}
                                </div>
                            </div>
                            <div className={"flex items-center text-sm italic text-[#808080]"}>
                                <span className={"w-[6px] h-[6px] bg-[#808080] rounded-full flex mr-2"}/>
                                <div className={""}>
                                    <FormattedMessage
                                        id={'subscription.purchasedate'}/>: {moment(item?.start * 1000).format("YYYY/MM/DD")}
                                </div>
                            </div>
                            <div className={"flex items-center text-sm italic text-[#808080]"}>
                                <span className={"w-[6px] h-[6px] bg-[#808080] rounded-full flex mr-2"}/>
                                <div className={""}>
                                    <FormattedMessage
                                        id={'subscription.nextPay.date'}/>: {moment(item?.current_period_end * 1000).format("YYYY/MM/DD")}
                                </div>
                            </div>
                            <div className={"flex items-center text-sm italic text-[#808080]"}>
                                <span className={"w-[6px] h-[6px] bg-[#808080] rounded-full flex mr-2"}/>
                                <div className={""}>
                                    <FormattedMessage id={'subscription.plan'}/>: {item?.plan?.interval}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={"text-end"}>
                    <button
                        onClick={handleDialogUnSub}
                        className={"bg-[#1790FF] text-white font-medium text-base p-2 rounded hover:opacity-80"}
                    ><FormattedMessage id={'subscription.cancel.plan'}/>
                    </button>
                </div>

            </DialogContent>

        </Dialog>
    );
}

export default DialogPlanManagement;
