import qs from 'query-string'
import { ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY } from "../../apis/constants";
import { setCookie } from "../../common/cookies";
import store from "store";

/**
 * @return {null}
 */
function SetCookies() {
    const query = qs.parse(window.location.search)
    //setCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY, query.token)
    store.set(ACCESS_TOKEN_KEY, query.token)
    setCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY, query.refreshToken)
    window.location.replace('/')
    return null
}

export default SetCookies
