import store from "store";
import { ACCOUNTS_KEY } from "../apis/constants";

export default function storeAccount(email) {
    let accounts = store.get(ACCOUNTS_KEY);
    if (!Array.isArray(accounts)) {
        accounts = [];
    }
    if (email && accounts.indexOf(email) === -1) {
        accounts.unshift(email);
        store.set(ACCOUNTS_KEY, accounts);
    }
}
