import {
    ACCESS_TOKEN_KEY,
    COOKIE_GENIAM_ACCESS_TOKEN_KEY,
    COOKIE_GENIAM_REFRESH_TOKEN_KEY,
    COOKIE_GENIAM_USER_INFO_KEY,
    ID_TOKEN_KEY,
    IS_SOCIAL_SIGN_IN,
    REFRESH_TOKEN_KEY
} from "../apis/constants";
import {removeCookie} from "./cookies";

export default () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ID_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(IS_SOCIAL_SIGN_IN);
    removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
    removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
    removeCookie(COOKIE_GENIAM_USER_INFO_KEY)

}
