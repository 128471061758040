import React, {useEffect, useState} from 'react';
import {MenuAppBar} from "../../LoadableComponents";
import qs from 'query-string'
import axios from "axios";
import {RESTORE_ACCOUNT} from "../../../apis/new_urls";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, LinearProgress} from '@material-ui/core'
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            border: "10px solid #3a3a3a",
            borderRadius: "10px",
        }
    },
    errorText: {
        color: "red"
    }
}))
function RestoreAccount(props) {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
    const [openErrorDialog, setOpenErrorDialog] = useState(false)
    const [err, setErr] = useState({})
    useEffect(() => {
        const query = qs.parse(window.location.search)
        const {userId, key} = query
        if (!userId || !key) {
            return window.location = "/"
        }
        setLoading(true)
        axios.post(RESTORE_ACCOUNT, {
            userId,
            token: key
        }).then(res => {
            setLoading(false)
            setOpenSuccessDialog(true)
        }).catch(e => {
            setLoading(false)
            if (e && e.response && e.response.data) {
                const {error_code, error_message, err, message, error} = e.response.data;
                if (error_code && error_message) {
                    console.log({error_code, error_message})
                }
                if (err || message) {
                    console.log({err, message});
                }

                setErr(error_message || message || err || error)
            }
            setOpenErrorDialog(true)

        })
    }, [])

    const onOk = () => {
        setOpenErrorDialog(false)
        setOpenSuccessDialog(false)
        return window.location = "/login"
    }
    return (
        <div>
            <MenuAppBar/>
            {loading &&
            <LinearProgress/>
            }
            <Dialog open={openSuccessDialog} className={classes.root}>
                <DialogContent>
                    <DialogContentText>
                        Account recovery was successful. Go to the login page
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={onOk}>
                            Ok
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={openErrorDialog} className={classes.root}>
                <DialogContent>
                    <DialogContentText className={classes.errorText}>
                        {err}
                    </DialogContentText>
                    <DialogContentText>
                        Error! An error occurred. Please try again later
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={onOk}>
                            Ok
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

        </div>
    );
}

export default RestoreAccount
