import qs from "query-string";
import { REDIRECT_URL_KEY } from "../apis/constants";

/**
 * localStorageSavedItems
 * [ query_key, local_storage_item ]
 */
const localStorageSavedItems = [
    ['redirect_url', REDIRECT_URL_KEY],
    ['service_id'],
    ['course_id']
]
export default (need) => {
    const parsed = qs.parse(window.location.search) || {};

    if (need)
        return parsed[need];

    localStorageSavedItems.forEach(item => {
        const val = parsed[item[0]]
        if (val) {
            const localStorageKey = item[1] || item[0]
            localStorage.setItem(localStorageKey, val)
        }
    })
    return parsed;
}
