// @ts-ignore
import Cookies from 'js-cookie'

const isProd = process.env.NODE_ENV === 'production'
const domain = isProd ? '.geniam.com' : null

const expires = 365 //days

export function getCookie(name, options = {}) {
    return Cookies.get(name, { domain, ...options })
}

export function setCookie(name, value, options = {}) {
    if(!value) return
    return Cookies.set(name, value, { domain, expires, ...options })
}

export function removeCookie(name, options = {}) {
    return Cookies.remove(name, { domain, ...options })
}

export function getJSONCookie(name, options = {}) {
    return Cookies.getJSON(name, { domain, ...options });
}
