import React, {useEffect, useReducer, useState} from 'react';
import {setGlobal, useGlobal} from 'reactn';
import classNames from "classnames";
import MenuAppBar from "../MenuAppBar/MenuAppBar";
import {FormattedMessage} from "react-intl";
import {makeStyles} from "@material-ui/styles";
import styled from "styled-components";
import TabContent from "./Content/TabContent";
import axios from "axios";
import {API_PREFIX} from "../../apis/new_urls";
import {orderBy} from 'lodash';
import DialogBase from "../Account/Settings/DialogBase";
import {toast} from "react-toastify";

const DivContainer = styled.div`
    max-width: 1000px;
`

const DivContent = styled.div`
    min-height: 200px;
`

export const services = (state, action) => {
    switch (action.type) {
        case 'list': {
            return {...state, list: action.list};
        }
        case 'allSub': {
            return {...state, allSub: action.allSub};
        }
        case 'selected': {
            return {...state, selected: action.selected};
        }
        case 'loading': {
            return {...state, loading: action.loading};
        }
        case 'tabList': {
            return {...state, tabList: action.tabList};
        }
        case 'reset': {
            return {
                ...state,
                list: [],
                allSub: [],
                tabList: [],
                selected: 0,
                loading: false
            };
        }
    }
};

function Subscription(props) {
    const classes = useStyles()
    const [state, dispatch] = useReducer(services, {
        list: [],
        allSub: [],
        tabList: [],
        selected: 0,
        loading: false
    })
    const [openUnsub, setOpenUnsub] = useGlobal('openUnsub')
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getServicesList()
        getPurchaseHistory()

    }, []);

    const getPurchaseHistory = async () => {
        try {
            const {data} = await axios.get(API_PREFIX + '/subscriptions/history')
            if (data?.data?.length)
                dispatch({type: 'allSub', allSub: orderBy(data.data, 'start_date', 'desc')})
        } catch (e) {
            console.log(e.toString())
        } finally {

        }
    }

    const getServicesList = async (ignoreLoading = false) => {
        try {
            if (!ignoreLoading)
                dispatch({type: 'loading', loading: true})
            const {data} = await axios.get(API_PREFIX + '/subscriptions/all')
            if (data?.data?.length)
                dispatch({type: 'list', list: orderBy(data.data, 'start_date', 'desc')})
        } catch (e) {
            console.log(e.toString())
        } finally {
            if (!ignoreLoading)
                dispatch({type: 'loading', loading: false})
        }
    }

    const handleUnsubscription = async () => {
        try {
            if (!openUnsub || !openUnsub?.id)
                return;
            setLoading(true);
            const {data} = await axios.post(API_PREFIX + '/subscriptions/cancel', {
                sub: openUnsub.id
            })
            await getServicesList(true)
            toast.success('サブスクリプションがキャンセルされました')
        } catch (e) {
            console.log(e.toString())
            toast.error(e.toString())
        } finally {
            setLoading(false)
            onCloseDialog()
        }
    }

    const onCloseDialog = () => {
        setGlobal({
            openUnsub: null
        })
    }
    return (
        <div className={classNames('v-centering')}>
            <MenuAppBar/>
            <div className="bg-gray-100 w-full min-h-screen">

                {/*<AppBar className={classes.setting_ttl} position="static">*/}
                {/*    <Toolbar>*/}
                {/*        <IconButton*/}
                {/*            className={classes.unit_icn_btn}*/}
                {/*            color="inherit"*/}
                {/*            aria-label="Menu">*/}
                {/*            <Person/>*/}
                {/*        </IconButton>*/}
                {/*        <Typography*/}
                {/*            variant="title"*/}
                {/*            color="inherit">*/}
                {/*            <FormattedMessage id={'home.subscription'}/>*/}
                {/*        </Typography>*/}
                {/*    </Toolbar>*/}
                {/*</AppBar>*/}

                <TabContent
                    state={state}
                    dispatch={dispatch}
                />


            </div>
            <DialogBase
                open={Boolean(openUnsub)}
                onClose={onCloseDialog}
                title={<FormattedMessage id={'subscription.dialog.title'}/>}
                onDone={handleUnsubscription}
                loading={loading}
                content={"サブスクリプションをキャンセルしても、期限日までは引き付きサービスをご利用いただけます。それ以降ご利用できなくなります。サブスクリプションをキャンセルしてもよろしいですか。"}
            />
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    setting_ttl: {
        background: '#3a3a3a',
    },
    unit_icn_btn: {
        marginRight: '20px',
        border: '1px solid',
    },
}))
export default Subscription;
