import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {FormattedMessage} from 'react-intl';
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import axios from 'axios'
import {API_PREFIX, METAMORS_PREFIX} from "../../../apis/new_urls";
import store from "store";
import {ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY, REDIRECT_URL_KEY} from "../../../apis/constants";
import _ from "lodash";
import qs from "query-string";
import {getCookie} from "../../../common/cookies";

function MailMagazineDialog({open, setOpen}) {
    const [receiveMailMagazine, setReceiveMailMagazine] = useState(true)
    const [loading, setLoading] = useState(false)
    const onConfirm = async () => {
        try {
            setLoading(true)
            const res = await axios.post(`${API_PREFIX}/mail-magazine-confirm`, {
                receiveMailMagazine
            })
            // check redirect
            const redirectTo = store.get(REDIRECT_URL_KEY)
            if (!redirectTo) {
                window.location.href = '/'
                return
            }
            if (_.includes(redirectTo, METAMORS_PREFIX) || _.includes(redirectTo, 'account.metamors.com')) {
                // const {data} = await axios.get(USER_INFO)
                return window.location.href = `${METAMORS_PREFIX}/?uuid=${res.data.userId}`;
            } else if (!_.includes(redirectTo, METAMORS_PREFIX)) {
                // const userInfo = store.get(USER_INFO_KEY)
                // if (!userInfo) {
                //     const {data} = await axios.get(USER_INFO)
                //     store.set(USER_INFO_KEY, data)
                // }
                let url = redirectTo
                if (url.includes("?")) {
                    url = url + "&" + qs.stringify({
                        token: store.get(ACCESS_TOKEN_KEY),
                        refreshToken: getCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
                    }, {sort: false})
                } else {
                    url = url + "?" + qs.stringify({
                        token: store.get(ACCESS_TOKEN_KEY),
                        refreshToken: getCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
                    }, {sort: false})
                }
                // console.log(url);
                return window.location.href = url

            } else {
                window.location.href = '/login/confirm';
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
        }
    }
    const onClose = () => {
        setOpen(false)
    }

    return (
        <Dialog open={open}
            // onClose={onClose}
        >
            <DialogTitle>
                Mail Magazine
            </DialogTitle>
            <DialogContent>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={receiveMailMagazine}
                                onChange={() => setReceiveMailMagazine(!receiveMailMagazine)}
                                // value={receive_mail_magazine}
                            />
                        }
                        className="bold"
                        label={<FormattedMessage id={"register.notice1"}/>}
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} disabled={loading}
                        onClick={onConfirm}
                >
                    <FormattedMessage id={"home.confirm"}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default MailMagazineDialog;
