import React from 'react';
import PropTypes from 'prop-types';
import {AppBar, Toolbar, withStyles} from '@material-ui/core';
import favicon from './favicon.ico';
import classNames from 'classnames';
// import EnvViewer from '../EnvViewer';
import {FormattedMessage} from 'react-intl';

const styles = theme => ({
  footer: {
    width: '100%',
    flexGrow: 1,
    marginTop: '20px',
  },
  smallText: {
    fontSize: 'small',
    color: 'white',
    },
    link: {
        textDecoration: 'none'
    }
});

class Footer extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.footer}>
                <AppBar position="static" style={{ backgroundColor: '#3A3A3A' }}>
                    <Toolbar className="v-centering">
                        <div className="centering">
                            <a href="https://www.andom.co.jp/" className={classNames(classes.link, 'm10')}
                               target="_blank" rel="noopener noreferrer">
                                <span className={classNames(classes.smallText)}>
                                    <FormattedMessage id={"footer.inventorInc"}/>
                                </span>
                            </a>
                            <a href="/account/terms" className={classNames(classes.link, 'm10')} target="_blank"
                               rel="noopener noreferrer">
                                <span className={classNames(classes.smallText)}>
                                    <FormattedMessage id={"home.termsOfService"}/>
                                </span>
                            </a>
                            <a href="/account/privacy" className={classNames(classes.link, 'm10')} target="_blank"
                               rel="noopener noreferrer">
                                <span className={classNames(classes.smallText)}>
                                    <FormattedMessage id={"home.privacyPolicy"}/>
                                </span>
                            </a>
                            <a href="/account/patent-commerce" className={classNames(classes.link, 'm10')}
                               target="_blank" rel="noopener noreferrer">
                                <span className={classNames(classes.smallText)}>
                                     <FormattedMessage id={"footer.notationBase"}/>
                                </span>
                            </a>
                        </div>
                        <div className={'flex flex-col justify-center items-center'}>
                          <img src={favicon} alt="favicon" width={30}
                               height={30}/>
                          <div className="bold large">
                              Geniam
                          </div>
                      </div>
                        <div className={classNames(classes.smallText, 'm10')}>
                            Copyright (C) {new Date().getFullYear()} Andom .inc
                            All Rights Reserved.
                        </div>
                        {/*<EnvViewer/>*/}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
