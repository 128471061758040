import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash'

export default createSlice({
  slice: 'deliveryAdds',
  name: 'deliveryAdds',
  initialState: [],
  reducers: {
    setDeliveryAdds: (state, { payload }) => {
      return payload
    },

    setNewAdd: (state, { payload: { newAdd, add } }) => {
      const idx = _.findIndex(state, { record_id: add.record_id })
      const newState = _.cloneDeep(state)
      return newState.map((_add, i) => {
        if (i !== idx) {
          // eslint-disable-next-line
          if (true == newAdd.main) {
            _add.main = "0"
          }
          return _add
        }
        return _.merge({}, add, newAdd)
      })
    }
  }
})

