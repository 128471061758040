import Loadable from 'react-loadable';
import Loading from './Loading/Loading'

// import React from "react";

function boundLoadable(loader) {
    return Loadable({
        loader: loader,
        loading: Loading,
    });
}

export const MenuAppBar = boundLoadable(() => import('./MenuAppBar/MenuAppBar'));

// For Account
export const Account = boundLoadable(() => import('./Account/Account'));
// export const AccountSettings = boundLoadable(() => import('./Account/Settings/Settings'));
export const AccountSettings2 = boundLoadable(() => import('./Account/Settings/Settings2'));
export const Medals = boundLoadable(() => import('./Account/Medals/index'));
export const Security = boundLoadable(() => import('./Account/LoginSecurity/LoginSecurity'));
export const MachineLearning = boundLoadable(() => import('./Account/MachineLearning/MachineLearning'));
export const InformationDistribution = boundLoadable(() => import('./Account/InformationDistribution/InformationDistribution'));
export const Payment = boundLoadable(() => import('./Account/Payment/Payment'));
export const Document = boundLoadable(() => import('./Account/Document/Document'));
export const DocumentFacebook = boundLoadable(() => import('./Account/FacebookAuth/DocumentFacebook'));
export const Support = boundLoadable(() => import('./Account/Support/Support'));
export const PaymentHistory = boundLoadable(() => import('./Account/Payment/History'));
export const BasicData = boundLoadable(() => import('./Account/BasicData/BasicData'));
export const Privacy = boundLoadable(() => import('./Account/Privacy/Privacy'));
export const PrivacyEng = boundLoadable(() => import('./Account/Privacy/PrivacyEng'));
export const PrivacyMentoroid = boundLoadable(() => import('./Account/Privacy/Mentoroid'));
export const Terms = boundLoadable(() => import('./Account/Terms/Terms'));
export const TermsEng = boundLoadable(() => import('./Account/Terms/TermsEng'));
export const TermsMentoroid = boundLoadable(() => import('./Account/Terms/Mentoroid'));
export const PatentCommerce = boundLoadable(() => import('./Account/PatentCommerce/PatentCommerce'));
export const DeleteService = boundLoadable(() => import('./Account/DeleteService/DeleteService'));
export const DeleteServiceConfirm = boundLoadable(() => import('./Account/DeleteService/DeleteServiceConfirm'));
export const DeliveryAddressSetting = boundLoadable(() => import('./Account/Payment/DeliveryAddressSetting'));
export const Inquiry = boundLoadable(() => import('./Account/Inquiry'));
export const History2 = boundLoadable(() => import('./Account/Payment/History2'));
export const AccountLink = boundLoadable(() => import('./Account/AccountLink'));
export const ContactMentoroid = boundLoadable(() => import('./Account/Mentoroid/ContactMentoroid'))

// For Authentication
export const EmailInputPopup = boundLoadable(() => import('./Auth/Register/EmailInputPopup'));
export const EmailConfirmPopup = boundLoadable(() => import('./Auth/Register/EmailConfirmPopup'));
export const EmailResend = boundLoadable(() => import('./Auth/Register/EmailResend'));
export const NamePasswordInputPopup = boundLoadable(() => import('./Auth/Register/NamePasswordInputPopup'));
export const SelectLanguagePopup = boundLoadable(() => import('./Auth/Register/SelectLanguagePopup'));
export const SelectTimezonePopup = boundLoadable(() => import('./Auth/Register/SelectTimezonePopup'));
export const PrivacyPolicyPopup = boundLoadable(() => import('./Auth/Register/PrivacyPolicyPopup'));
export const ConfirmPopup = boundLoadable(() => import('./Auth/Register/ConfirmPopup'));
export const EmailNotSent = boundLoadable(() => import('./Auth/Register/EmailNotSent'));
export const Disagree = boundLoadable(() => import('./Auth/Register/Disagree'));

export const LoginEmailInputPopup = boundLoadable(() => import('./Auth/Login/EmailInputPopup'));
export const LoginPasswordInputPopup = boundLoadable(() => import('./Auth/Login/PasswordInputPopup'));
export const LoginEmailConfirm = boundLoadable(() => import('./Auth/Login/LoginEmailConfirm'));
export const SelectAccount = boundLoadable(() => import('./Auth/Login/SelectAccount'));
export const ForgotPassword = boundLoadable(() => import('./Auth/Login/ForgotPassword'));
export const ForgotPasswordNewPassword = boundLoadable(() => import('./Auth/Login/ForgotPasswordNewPassword'));

// For payment

