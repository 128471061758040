import {CHANGE_ACCOUNT_INFO_URL, UPDATE_USER_DATA_URL, USER_INFO,} from './new_urls';
import axios from 'axios';
import {USER_INFO_KEY} from './constants';
import store from 'store';
import isPlainObject from 'lodash/isPlainObject';
import isRegisterOrLogin from '../common/isRegisterOrLogin';
import isTermsOrPrivacy from '../common/isTermsOrPrivacy';
import isRestoreAccount from "../common/isRestoreAccount";
import {setGlobal} from 'reactn';
import isSocialAuth from "../common/isSocialAuth";

export async function getUserInfoAsync(force = false, cancelToken) {
    if (isRestoreAccount() || isRegisterOrLogin() || isTermsOrPrivacy() ||
        isSocialAuth()
    ) return {};
    const localUserInfo = store.get(USER_INFO_KEY);
    if (!force && localUserInfo && isPlainObject(localUserInfo)) {
        // store.set(USER_INFO_KEY, localUserInfo);
        setGlobal({
            language: localUserInfo.language,
        });
        return localUserInfo;
    }
    const res = await axios.get(USER_INFO, cancelToken);
    if (res) {
        store.set(USER_INFO_KEY, res.data);
        setGlobal({
            language: res.data.language,
        });
        return res.data;
    }
    return {};
}

export async function updateUserData(data) {
  return await axios.post(UPDATE_USER_DATA_URL, data);
}

export async function changeAccountInfo(data) {
  return await axios.post(CHANGE_ACCOUNT_INFO_URL, data);
}
