import {addCallback, setGlobal} from 'reactn';
import ls from 'local-storage';

addCallback(global => {
    if (process.env.NODE_ENV !== 'production')
        console.log(' Global ', global);
    if (global.avatar_changed === true) {
        window.location.reload();
        return;
    }
});

setGlobal({
    paymentHistories: [],
    paymentHistoryBeforeDate: null,
    language: ls.get('language') || 'ja-jp',
    openUnsub: null
});
