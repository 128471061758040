import { ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY, ID_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../apis/constants";
import { setCookie } from "./cookies";

export default (resOrErrRes, then) => {
    if (!resOrErrRes) return false;
    const tokenNames = [REFRESH_TOKEN_KEY, ACCESS_TOKEN_KEY, ID_TOKEN_KEY];
    const data = (resOrErrRes instanceof Error) ? (resOrErrRes.response ? resOrErrRes.response.data : null) : resOrErrRes.data;

    if (data) {
        let succ = false;
        tokenNames.forEach(name => {
            const val = data[name];
            if (val) {
                succ = true;
                if (name === REFRESH_TOKEN_KEY) {
                    setCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY, data[REFRESH_TOKEN_KEY])
                } else {
                    localStorage.setItem(name, val)
                }
            }
        });
        if (then) then(succ)
    }
}
