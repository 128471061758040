import { ACCESS_TOKEN_KEY } from "../apis/constants";
import parseJwt from "./parseJwt";

export default function () {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY)
    if (!accessToken) return true;

    const jwt = parseJwt(accessToken);
    if (!jwt) return true;
    // console.log(jwt)
    const { exp } = jwt;
    if (!exp) return true;
    const nowInSeconds = Math.floor(+new Date() / 1000);
    return exp < nowInSeconds;
}
