import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {isMobileOnly} from "react-device-detect";
import {FormattedMessage} from "react-intl";

class DialogBase extends Component {

    render() {
        const {open, onClose, title, content, children, onDone, loading, errorMsg} = this.props;
        let {doneBtnText = <FormattedMessage id={"home.done"}/>, hideCancelButton} = this.props;
        if (loading) {
            doneBtnText = <FormattedMessage id={"home.updating"}/>
        }
        return (
            <div>
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={open || false}
                    onClose={onClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={isMobileOnly ? 'md' : 'sm'}
                >
                    <div className="bgWhite">
                        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {content}
                            </DialogContentText>
                            {children}
                        </DialogContent>
                        <DialogActions>
                            {
                                errorMsg ?
                                    <div style={{color: "red"}}>
                                        {errorMsg}
                                    </div>
                                    : null
                            }
                            <div className="mr10 pr10">
                                {!hideCancelButton &&
                                <Button onClick={onClose}
                                        className="btnSecondary"
                                        disabled={loading}>
                                    <FormattedMessage id={"home.cancel"}/>
                                </Button>
                                }
                            </div>
                            <div>
                                <Button onClick={onDone} className="btnPrimary"
                                        disabled={loading || (errorMsg && errorMsg!=="")}>
                                    {doneBtnText}
                                </Button>
                            </div>
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
        )
    }

}

export default DialogBase;
