import { ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY, REDIRECT_URL_KEY } from "../apis/constants";
import createQueryParams from "./createQueryParams";
import { getCookie } from "./cookies";

import store from 'store'

export default (redirect_url) => {
    redirect_url = redirect_url || store.get(REDIRECT_URL_KEY);
    if (redirect_url) {
        const token = store.get(ACCESS_TOKEN_KEY)
        const refreshToken = getCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY);
        let searchMark = "?";
        if (redirect_url && (redirect_url.indexOf("?") > -1)) {
            searchMark = "&"
        }
        return redirect_url + searchMark + createQueryParams({ token, refreshToken })
    }
    return false;
}
