const {REACT_APP_ENV} = process.env
export const IS_PRODUCTION = REACT_APP_ENV === "production"
export const ACCESS_TOKEN_KEY = 'accessToken';
export const ID_TOKEN_KEY = 'idToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const LOGIN_KEY = 'login';
export const REGISTER_KEY = 'register';
export const ACCOUNTS_KEY = 'accounts';
export const USER_INFO_KEY = 'userInfo';
export const LANGUAGE_KEY = 'language';
export const REDIRECT_URL_KEY = 'service_redirect_url';
export const USER_INFO_CHANGED_KEY = 'userInfoChanged';
export const LAST_LOGIN_KEY = 'last_login';
export const COOKIE_GENIAM_ACCESS_TOKEN_KEY = `ge_${REACT_APP_ENV}_accessToken`
export const COOKIE_GENIAM_REFRESH_TOKEN_KEY = `ge_${REACT_APP_ENV}_refreshToken`
export const COOKIE_GENIAM_USER_INFO_KEY = `ge_${REACT_APP_ENV}_userInfo`
export const FB_RESPONSE_KEY = 'fbLoginStatusResponse'
export const IS_SOCIAL_SIGN_IN = 'isSocialSignIn'
