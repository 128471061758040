import React, {useState} from 'react';
import {Button} from 'antd';
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import {useGlobal} from "reactn";
import moment from "moment";
import {numberFormat} from "../../Account/Payment/History2";
import DialogPlanManagement from "./DialogPlanManagement";


const ButtonCustom = styled(Button)`
    margin-top: 10px;
`

function ItemSub({item, value}) {
    const [open, setOpen] = useState(false)
    const [language] = useGlobal("language")
    const handleOpenDialog = (item) => {
        setOpen(true)
    }
    console.log(item, "item")
    return (
        <div className={'bg-white rounded-md p-4'}>
            <div className={'flex items-center'}>

                <img loading="lazy" src={item?.product_data?.images[0] || '/img/geniam.svg'}
                     className={'object-contain w-24 h-24 mr-2'} alt=''/>

                <div className={"flex flex-col"}>
                    <div>
                        <div
                            className={'text-blue-500 font-bold'}>{item?.plan?.name || item?.lines?.data[0]?.plan?.name}</div>
                        <div
                            className={"text-sm text-[#808080]"}>{item?.description || item?.product_data?.description || item?.lines?.data[0]?.description}</div>
                    </div>

                </div>
            </div>
            {
                value === 0 ? <div className={"grid grid-cols-3 gap-4 mb-4"}>
                        <div className={"border-solid border border-[#eaeaea] text-center p-1 rounded"}>
                            <div className={"text-[#808080]"}><FormattedMessage id={'subscription.purchasedate'}/></div>
                            <div className={"font-medium"}>{moment(item?.start * 1000).format("YYYY/MM/DD")}</div>
                        </div>
                        <div className={"border-solid border border-[#eaeaea] text-center p-1 rounded"}>
                            <div className={"text-[#808080]"}><FormattedMessage id={'subscription.nextPay.date'}/></div>
                            <div
                                className={"font-medium"}>{moment(item?.current_period_end * 1000).format("YYYY/MM/DD")}</div>
                        </div>
                        <div className={"border-solid border border-[#eaeaea] text-center p-1 rounded"}>
                            <div className={"text-[#808080]"}><FormattedMessage id={'subscription.plan'}/></div>
                            <div className={"font-medium capitalize"}>{item?.plan?.interval}</div>
                        </div>
                    </div>
                    :
                    <div className={"grid grid-cols-2 gap-4 mb-4"}>
                        <div className={"border-solid border border-[#eaeaea] text-center p-1 rounded"}>
                            <div className={"text-[#808080]"}><FormattedMessage id={'subscription.purchasehistory'}/>
                            </div>
                            <div className={"font-medium"}>{moment(item?.date * 1000).format("YYYY/MM/DD")}</div>
                        </div>
                        <div className={"border-solid border border-[#eaeaea] text-center p-1 rounded"}>
                            <div className={"text-[#808080]"}><FormattedMessage id={'subscription.plan'}/></div>
                            <div className={"font-medium capitalize"}>{item?.lines?.data[0]?.plan?.interval}</div>
                        </div>
                    </div>
            }

            <div className={"border-solid border border-[#eaeaea] p-2 rounded mb-4"}>
                <div className={"text-[#808080]"}><FormattedMessage id={'subscription.priceincludingtax'}/></div>
                <div className={"font-medium text-[#FE0000] text-base"}>
                    {numberFormat(language, item?.plan?.amount || item?.amount_paid)}
                </div>
            </div>
            {
                value === 0 &&
                <button
                    className={"w-full bg-[#1790FF] text-white font-medium text-base p-2 rounded hover:opacity-80"}
                    onClick={handleOpenDialog}
                ><FormattedMessage id={'account.planmanagement'}/></button>
            }
            <DialogPlanManagement
                open={open}
                setOpen={setOpen}
                item={item}
            />
        </div>
    );
}

export default ItemSub;
