import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Container, LinearProgress} from "@material-ui/core";
import {filter} from 'lodash';
import {FormattedMessage} from "react-intl";
import ItemSub from "./Item";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        overflow: "hidden",
        "& .MuiPaper-root .MuiAppBar-root .MuiAppBar-positionStatic .MuiAppBar-colorDefault .MuiPaper-elevation4": {
            borderRadius: "10px 10x 0px 0px"
        }
    },
    divNoContent: {
        padding: 20,
        textAlign: 'center'
    }
}));

export default function TabContent({state, dispatch}) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [list, setList] = useState([])

    useEffect(() => {
        getTabValues(value)
    }, [value, state?.list, state?.allSub])

    const getTabValues = (value) => {
        if (!state?.list?.length)
            return;
        let tabValues
        if (value === 0)
            tabValues = filter(state.list, l => l.status === 'active' && !l.cancel_at_period_end)
        else
            tabValues = filter(state.allSub, l => l.status === 'paid')

        setList(tabValues)
    }
    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    return (
        <Container>
            <button onClick={() => handleChange(0)}
                    className={`font-bold relative p-4 after:content-[''] after:h-6 after:w-0.5 after:bg-white after:absolute after:right-0 ${value === 0 ? 'text-blue-500' : 'text-gray-400'}`}>
                <FormattedMessage id={'subscription.registeredservices'}/></button>
            <button onClick={() => handleChange(1)}
                    className={`font-bold p-4 ${value === 1 ? 'text-blue-500' : 'text-gray-400'}`}><FormattedMessage
                id={'subscription.usedhistory'}/></button>
            <div>
                {
                    state.loading ? <LinearProgress/>
                        :
                        !list?.length ?
                            <div className={classes.divNoContent}><FormattedMessage id={'subscription.nodatafound'}/>
                            </div>
                            :
                            <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"}>
                                {
                                    list.map((item, index) => {
                                        return (
                                            <ItemSub
                                                item={item}
                                                value={value}
                                            />
                                        )
                                    })
                                }
                            </div>
                }
            </div>
        </Container>
    );
}
