import React, {createContext, useEffect, useState} from 'react';
import store from 'store';
import {USER_INFO_KEY} from '../apis/constants';
import {getUserInfoAsync} from '../apis/user';
import isPlainObject from 'react-redux/lib/utils/isPlainObject';

export const UserContext = createContext({});

export const UserContextProvider = ({children}) => {
  const {userInfo, setUserInfo, mergeUserInfo} = useUserInfo();
  return (
      <UserContext.Provider value={{userInfo, setUserInfo, mergeUserInfo}}>
        {children}
      </UserContext.Provider>
  );
};

const useUserInfo = () => {
  const [userInfo, setUserInfo] = useState(store.get(USER_INFO_KEY) || {});
  // console.log('useUserInfo', userInfo);
  useEffect(() => {
    getUserInfoAsync().then(info => {
      setUserInfo(info);
    }).catch(e => {
      console.log(e);
    });
  }, []);

  const mergeUserInfo = data => {
    if (isPlainObject(data)) {
      setUserInfo({...userInfo, ...data});
    }
  };

  return {userInfo, setUserInfo, mergeUserInfo};
};
