import React from 'react'
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";

class LoginRegisterButtons extends React.Component {

    render() {
        return (
            <div className="flex-left-right">
                <div className="mr10">
                    <Button
                        className='btnPrimary'
                        size={"small"}
                        component={props => <Link to="/login" {...props} />}
                    >ログイン</Button>
                </div>

                <div>
                    <Button
                        className="btn"
                        size={"small"}
                        style={{ backgroundColor: '#eac545', borderBottom: '3px solid #B79224' }}
                        component={props => <Link to="/register" {...props} />}
                    >
                        新規登録
                    </Button>
                </div>
            </div>
        );
    }
}

export default LoginRegisterButtons

