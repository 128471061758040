import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import logger from 'redux-logger'
import {reduxBatch} from '@manaflair/redux-batch'
import rootReducer from './rootReducer'
import {isProductionEnv} from "../common/env";

const middleware = [...getDefaultMiddleware()]
//add logger if is developing
if (!isProductionEnv()) {
    middleware.push(logger)
}

const preloadedState = {}

const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: !isProductionEnv(),
    preloadedState,
    enhancers: [reduxBatch]
});

export default store

// The store has been created with these options:
// - The slice reducers were automatically passed to combineReducers()
// - redux-thunk and redux-logger were added as middleware
// - The Redux DevTools Extension is disabled for production
// - The middleware, batch, and devtools enhancers were automatically composed together
