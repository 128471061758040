export default () => {
    const { protocol, host, pathname, search } = window.location;
    const isOldResetPasswordLink =
        pathname.indexOf("/forgot-password/new-password") > -1
        && pathname.indexOf("/login/forgot-password/new-password") === -1;
    if (isOldResetPasswordLink) {
        const newLink = `${protocol}//${host}/login${pathname}${search}`;
        console.log({ newLink });
        window.location.href = `${protocol}//${host}/login${pathname}${search}`
    }
    return isOldResetPasswordLink;
}
