import { ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY, ID_TOKEN_KEY } from "../apis/constants";
import axios from "axios";
import { getCookie } from "./cookies";
import store from "store";

/**
 *
 * @returns {Promise<null|{idToken, accessToken, refreshToken}>}
 */
export default async function () {
    // console.log("refreshToken()");
    const localRefreshToken = getCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
    // console.log(localRefreshToken)
    if (!localRefreshToken) {
        return null;
    }
    try {
        // console.log("refreshing Access Token");
        const { data: { accessToken, idToken } } =
            await axios.post('/refresh', { refreshToken: localRefreshToken });
        localStorage.setItem(ID_TOKEN_KEY, idToken);
        //setCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY, accessToken)
        store.set(ACCESS_TOKEN_KEY, accessToken)

        return { accessToken, idToken };
    } catch (e) {
        // if (isRefreshTokenInvalidate(e)) {
        //     removeAllTokens();
        //     window.location.href = '/login'
        // }
        return null;
    }
};
