import {ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY, REDIRECT_URL_KEY, USER_INFO_KEY} from "../apis/constants";
import {setCookie} from "./cookies";
import axios from "axios";
import {METAMORS_PREFIX, USER_INFO} from "../apis/new_urls";
import store from "store";
import _ from "lodash";
import qs from "query-string";
import removeLoginOrRegisterKeys from "./removeLoginOrRegisterKeys";


export const setTokenAndRedirect = async ({
                                              access_token,
                                              refresh_token,
                                          }) => {
    try {
        localStorage.setItem(ACCESS_TOKEN_KEY, access_token)
        setCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY, refresh_token)

        const res = await axios.get(USER_INFO)
        store.set(USER_INFO_KEY, res.data)

        // check new user sign up to open mail magazine
        // if (data.isNew) {
        //     setOpenMailMagazin(true)
        //     return
        // }
        const redirectTo = store.get(REDIRECT_URL_KEY)
        removeLoginOrRegisterKeys()
        if (!redirectTo) {
            window.location.href = '/'
            return
        }
        if (_.includes(redirectTo, METAMORS_PREFIX) || _.includes(redirectTo, 'account.metamors.com')) {
            // const {data} = await axios.get(USER_INFO)
            return window.location.href = `${METAMORS_PREFIX}/?uuid=${res.data.user_id}`;
        } else if (!_.includes(redirectTo, METAMORS_PREFIX)) {

            let url = redirectTo
            if (url.includes("?")) {
                url = url + "&" + qs.stringify({
                    token: access_token,
                    refreshToken: refresh_token
                }, {sort: false})
            } else {
                url = url + "?" + qs.stringify({
                    token: access_token,
                    refreshToken: refresh_token
                }, {sort: false})
            }
            // console.log(url);
            return window.location.href = url

        } else {
            window.location.href = '/login/confirm';
        }
    } catch (e) {
        console.log(e);
    }
}
