import {useDocumentData} from 'react-firebase-hooks/firestore';
import Fire from '../Fire';

// export const getDataSheets = async (language) => {
//   try {
//     const {data} = await axios.get(API_PREFIX + '/getLanguages', {
//       params: {
//         language: language,
//         sheetId: 0,
//       },
//     });
//     if (!data)
//       return null;
//     return data;
//   } catch (e) {
//     console.log(e.toString());
//   }
// };

export const useLangMessages = (lang) => {

  const [m, mLoading, mError] = useDocumentData(
      lang ?
          Fire.firestore().
              doc(`/intl/myaccountintl/langColl/${lang.toLowerCase()}`)
          :null,
  );

  // if (mError) console.log(mError);
  // console.log(m)
  return [m, mLoading, mError];
};
