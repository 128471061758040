import axios from "axios";
import {ACCESS_TOKEN_KEY, LAST_LOGIN_KEY, LOGIN_KEY, REGISTER_KEY} from "../apis/constants";
import storeAccount from "../common/storeAccount";
import store from "store";
import isRegisterOrLogin from "../common/isRegisterOrLogin";
import redirect from "../common/redirect";
import saveTokens from "../common/saveTokens";
import {toast} from "react-toastify";
import {API_PREFIX} from "../apis/new_urls";
import showAxiosError from "../common/showAxiosError";
import {isStrIncludes} from "./localTokens";
import {isDevelopmentEnv} from "../common/env";
import refreshToken from "../common/refreshToken";
import isLocalAccessTokenExpired from "../common/isLocalAccessTokenExpired";
import isInquiry from "../common/isInquiry";

axios.defaults.baseURL = API_PREFIX

export default () => {
    //REQUEST
    axios.interceptors.request.use((config) => {
        console.log(`---- ${config.method.toUpperCase()} ${config.url} ----`);
        if (isDevelopmentEnv()) {
            console.log(config);
        }
        if (isIgnoredCheckPath(config.url)) {
            return config
        }

        const accessToken = store.get(ACCESS_TOKEN_KEY)
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    }, error => {
        if (error && error.request) {
            console.log(error);
            console.log(error.request);
            toast.error(error.toString())
        }
        return Promise.reject(error);
    });

    //RESPONSE
    axios.interceptors.response.use(
        function (response) {
            if (isRegisterOrLogin()) {
                saveTokens(response, (isTokenExists) => {
                    if (!isTokenExists) {
                        return;
                    }
                    response.redirectTo = redirect() || '/';
                    const email = (store.get(LOGIN_KEY) || {}).email || (store.get(REGISTER_KEY) || {}).email;
                    // console.log({ email });
                    if (email) {
                        storeAccount(email);
                        localStorage.setItem(LAST_LOGIN_KEY, email)
                    }
                });
            }
            return response;
        },
        async function (error) {
            console.log('axios error:');
            console.log(error);

            if (isIgnoredCheckPath(error.config.url)) {
                return Promise.reject(error);
            }
            const {status, data} = error.response;
            const codes = ["auth/id-token-expired", "auth/argument-error"]
            if ((status === 401
                    || status === 400 || status === 500)
                && (data?.error === "login required" || codes.includes(data?.error?.code))
            ) {
                //try refresh token
                const data = await refreshToken();
                if (!data) {
                    window.location.replace('/login')
                    return Promise.reject(error)
                }
                error.config.headers['Authorization'] = `Bearer ${data.accessToken}`;
                return await axios(error.config);
            }
            if (isInquiry()) {
                return Promise.reject(error);
            }
            if (isLocalAccessTokenExpired()) {
                window.location.href = '/login'
                return
            }
            showAxiosError(error)
            return Promise.reject(error);
        }
    );
}


function isIgnoredCheckPath(url) {
    let paths;
    paths = ['/refresh', '/login', '/register', '/signup', '/signup2', '/restore'];
    let ignored = false
    paths.some(p => {
        if (isStrIncludes(url, p)) {
            ignored = true
            return true
        }
        return false
    })
    return ignored
}
