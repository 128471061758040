import Cookies from 'js-cookie'
import _ from 'lodash'
import {
    COOKIE_GENIAM_ACCESS_TOKEN_KEY,
    COOKIE_GENIAM_REFRESH_TOKEN_KEY,
    COOKIE_GENIAM_USER_INFO_KEY
} from "../apis/constants";

export default () => {
    const geniam_accessToken = Cookies.get(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
    const geniam_refreshToken = Cookies.get(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
    const geniam_userInfo = Cookies.getJSON(COOKIE_GENIAM_USER_INFO_KEY)
    return { geniam_accessToken, geniam_refreshToken, geniam_userInfo }
}

export const isStrIncludes = (str, includes) => {
    if (!_.isString(str) || _.isEmpty(str)) return false
    if (!_.isString(includes) || _.isEmpty(includes)) return false
    return str.toLowerCase().includes(includes.toLowerCase())
}
export const isRefreshUrl = (str) => {
    return isStrIncludes(str, '/refresh')
}
