import React, {useEffect} from 'react';
import {useGlobal} from 'reactn';
import {Route, Switch, withRouter} from 'react-router-dom';
import {
    Account,
    AccountLink,
    AccountSettings2,
    BasicData,
    ConfirmPopup,
    ContactMentoroid,
    DeleteService,
    DeleteServiceConfirm,
    DeliveryAddressSetting,
    Disagree,
    Document,
    DocumentFacebook,
    EmailConfirmPopup,
    EmailInputPopup,
    EmailNotSent,
    EmailResend,
    ForgotPassword,
    ForgotPasswordNewPassword,
    History2,
    InformationDistribution,
    Inquiry,
    LoginEmailConfirm,
    LoginEmailInputPopup,
    LoginPasswordInputPopup,
    MachineLearning,
    Medals,
    NamePasswordInputPopup,
    PatentCommerce,
    Payment,
    Privacy,
    PrivacyEng,
    PrivacyMentoroid,
    PrivacyPolicyPopup,
    Security,
    SelectAccount,
    SelectLanguagePopup,
    SelectTimezonePopup,
    Support,
    Terms,
    TermsEng,
    TermsMentoroid,
} from './components/LoadableComponents';
import redirectIfOldResetPasswordLink from './common/redirectIfOldResetPasswordLink';
//google analytics
import * as ReactGA from 'react-ga';
import axios from 'axios';
import {HEALTHCHECK_URL} from './apis/new_urls';
import showAxiosError from './common/showAxiosError';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SetCookies from './components/SetCookies/SetCookies';
import qs from 'query-string';
import {removeCookie, setCookie} from './common/cookies';
import {
    ACCESS_TOKEN_KEY,
    COOKIE_GENIAM_ACCESS_TOKEN_KEY,
    COOKIE_GENIAM_REFRESH_TOKEN_KEY,
    COOKIE_GENIAM_USER_INFO_KEY,
} from './apis/constants';
import store from 'store';
import {IntlProvider} from 'react-intl';
import LinearProgress from '@material-ui/core/LinearProgress';
import {useLangMessages} from './apis/languages';
import RestoreAccount from "./components/Account/RestoreAccount";
import AuthenticationCode from "./components/Auth/AuthenticationCode";
import Subscription from "./components/Subscription/Subscription";
import {getUserInfoAsync} from "./apis/user";
import redirect from "./common/redirect";
import 'antd/dist/antd.css';

ReactGA.initialize('UA-123685291-3');
ReactGA.pageview(window.location.pathname + window.location.search);

function App({history}) {
    const [language] = useGlobal('language');
    const [message] = useLangMessages(language);

    useEffect(() => {
        removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY);
        removeCookie(COOKIE_GENIAM_USER_INFO_KEY);
        healthCheck();
        refreshInfo()

    }, []);
    const refreshInfo = async () => {
        try {
            await getUserInfoAsync(true)
        } catch (e) {

        }
    }

    const healthCheck = async () => {
        try {
            const {token, refreshToken, service_redirect_url} = qs.parse(window.location.search);
            if (token || refreshToken) {
                //setCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY, token)
                store.set(ACCESS_TOKEN_KEY, token);
                setCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY, refreshToken);
                if (service_redirect_url) {
                    if (service_redirect_url.startsWith("http://")) {
                        window.location.href = redirect(service_redirect_url)
                        return
                    }
                    window.open(redirect(service_redirect_url))
                    return;
                }
                history.push('/');
            }

            await axios.get(HEALTHCHECK_URL);
        } catch (e) {
            showAxiosError(e);
            // toast.error("接続が中断されました。再接続しています...")
        }
    };

    if (redirectIfOldResetPasswordLink()) return null;
    if (!message) return <LinearProgress/>;
    return (
        <IntlProvider locale={language} key={language} messages={message}
                      timeZone="Asia/Tokyo">
            <div className="account-setting">
                <Switch>
                    {/*<Route exact path="/" component={MenuAppBar}/>*/}
                    <Route exact path="/" component={Account}/>
                    <Route exact path="/account" component={Account}/>
                    <Route exact path="/account/settings" component={AccountSettings2}/>
                    <Route exact path="/account/medals" component={Medals}/>
                    <Route exact path="/account/security" component={Security}/>
                    <Route exact path="/account/machine-learning"
                           component={MachineLearning}/>
                    <Route exact path="/account/information-distribution"
                           component={InformationDistribution}/>
                    <Route exact path="/account/payment" component={Payment}/>
                    <Route exact path="/account/subscription" component={Subscription}/>
                    <Route exact path="/account/document" component={Document}/>
                    <Route exact path="/account/document-facebook-auth" component={DocumentFacebook}/>
                    <Route exact path="/account/support" component={Support}/>
                    <Route exact path="/account/payment/history" component={History2}/>
                    <Route exact path="/account/basic-data" component={BasicData}/>
                    <Route exact path="/account/privacy" component={Privacy}/>
                    <Route exact path="/account/privacy/eng" component={PrivacyEng}/>
                    <Route exact path="/account/privacy/roidemia" component={PrivacyMentoroid}/>
                    <Route exact path="/account/terms" component={Terms}/>
                    <Route exact path="/account/terms/eng" component={TermsEng}/>
                    <Route exact path="/account/terms/roidemia" component={TermsMentoroid}/>
                    <Route exact path="/account/restore" component={RestoreAccount}/>
                    <Route exact path="/account/patent-commerce"
                           component={PatentCommerce}/>
                    <Route exact path="/account/delete-service"
                           component={DeleteService}/>
                    <Route exact path="/account/delete-service/confirm"
                           component={DeleteServiceConfirm}/>
                    <Route exact path="/account/delivery-address-setting"
                           component={DeliveryAddressSetting}/>

                    <Route exact path="/account/inquiry" component={Inquiry}/>
                    <Route exact path="/account/inquiry/roidemia" component={ContactMentoroid}/>
                    <Route exact path="/account/account-link" component={AccountLink}/>

                    <Route exact path="/register" component={EmailInputPopup}/>
                    <Route exact path="/register/email-confirm"
                           component={EmailConfirmPopup}/>
                    <Route exact path="/register/email-resend" component={EmailResend}/>
                    <Route exact path="/register/name-password-input"
                           component={NamePasswordInputPopup}/>
                    <Route exact path="/register/select-language"
                           component={SelectLanguagePopup}/>
                    <Route exact path="/register/select-timezone"
                           component={SelectTimezonePopup}/>
                    <Route exact path="/register/privacy-policy"
                           component={PrivacyPolicyPopup}/>
                    <Route exact path="/register/confirm" component={ConfirmPopup}/>
                    <Route exact path="/register/email-not-sent"
                           component={EmailNotSent}/>
                    <Route exact path="/register/disagree" component={Disagree}/>

                    <Route exact path="/login" component={LoginEmailInputPopup}/>
                    <Route exact path="/login/password-input"
                           component={LoginPasswordInputPopup}/>
                    <Route exact path="/login/confirm" component={LoginEmailConfirm}/>
                    <Route exact path="/login/select-account"
                           component={SelectAccount}/>
                    <Route exact path="/login/forgot-password"
                           component={ForgotPassword}/>
                    <Route exact path="/login/forgot-password/new-password"
                           component={ForgotPasswordNewPassword}/>
                    <Route exact path="/set-cookies" component={SetCookies}/>

                    <Route exact path={'/socialAuth'} component={AuthenticationCode}/>
                </Switch>
                <ToastContainer
                    hideProgressBar={true}
                    closeOnClick={false}
                    autoClose={3000}
                />
            </div>
        </IntlProvider>
    );
}

export default withRouter(App);
