import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Router} from 'react-router-dom';
import configAxios from './config/axios';
import history from './history';
import {Provider} from 'react-redux';
import store from './redux/store';
import qsParse from './common/qsParse';
import 'moment/locale/ja';
import 'moment/locale/en-ca';
import {UserContextProvider} from './context/UserContext';
import "@Andom-Geniam/git-globalnavi/dist/index.css"

require('./setGlobal');

configAxios();
qsParse();

ReactDOM.render((
    <Provider store={store}>
      <UserContextProvider>
        <Router history={history}>
          <App/>
        </Router>
      </UserContextProvider>
    </Provider>
), document.getElementById('root'));
// registerServiceWorker();
serviceWorker.unregister();
