import React from 'react';
import PropTypes from 'prop-types';
import {AppBar, IconButton, Toolbar, Typography, withStyles,} from '@material-ui/core';
import {Apps, ShoppingCart} from '@material-ui/icons';
import AppListPopup from './AppListPopup';
// import AccountPopup from './AccountPopup';
import {Link} from 'react-router-dom';
import LoginRegisterButtons from './LoginRegisterButtons';
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, USER_INFO_CHANGED_KEY, USER_INFO_KEY} from '../../apis/constants';
import createQueryParams from '../../common/createQueryParams';
import store from 'store';
import {UserContext} from '../../context/UserContext';
import {NavigationGlobal} from '@Andom-Geniam/git-globalnavi'
import axios from "axios";
import {LOGOUT_URL} from "../../apis/new_urls";
import removeAllTokens from "../../common/removeAllTokens";
import removeLoginOrRegisterKeys from "../../common/removeLoginOrRegisterKeys";
import showAxiosError from "../../common/showAxiosError";
import {auth, db, storage} from '../../Fire'
import history from '../../history'
import {changeAccountInfo, getUserInfoAsync} from "../../apis/user";

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100vw',
    },
    flex: {
        flex: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    typography: {
        margin: theme.spacing(2),
    },
    avatar: {
        width: 40,
        height: 40,
    },
    icons: {
        fontSize: 30,
        fill: '#434343'
    },
    mr10: {
        marginRight: 10
    }
});

class MenuAppBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            appListPopupAnchorEl: null,
            ...store.get(USER_INFO_KEY),
            isLoggingIn: store.get(ACCESS_TOKEN_KEY),
        };
    }

    openAccountPopup = (e) => {
        this.setState({
            accountPopupAnchorEl: e.currentTarget,
        });
    };

    closeAccountPopup = (e) => {
        this.setState({
            accountPopupAnchorEl: null,
        });
    };

    showAppList = (e) => {
        this.setState({
            appListPopupAnchorEl: e.currentTarget,
        });
    };
    closeAppListPopup = (e) => {
        this.setState({
            appListPopupAnchorEl: null,
        });
    };
    onClickAccount = () => {
        history.push('/account/settings')
    };
    onLogout = async (e) => {
        try {
            await axios.post(LOGOUT_URL);
            await auth.signOut()

            removeAllTokens();
            removeLoginOrRegisterKeys();
            window.location.href = "/login"
        } catch (e) {
            showAxiosError(e);
            console.log(e)
        }
    };

    render() {
        const {classes} = this.props;
        const {isLoggingIn} = this.state;
        return (
            <UserContext.Consumer>
                {
                    ({userInfo, setUserInfo, mergeUserInfo}) => {
                        return (
                            <div className={classes.root}>
                                <AppBar position="static"
                                        style={{backgroundColor: '#FFFFFF', padding: '0'}}>
                                    <Toolbar style={isLoggingIn ? {paddingRight: '10px'} : {}}>

                                        <Typography color={'inherit'} variant={'subtitle1'}
                                                    className={classes.flex}>
                                            <Link style={{color: 'black', fontSize: "24px"}}
                                                  className={'noUnderline'}
                                                  to="/">Geniam</Link>
                                        </Typography>

                                        <IconButton color={'inherit'} onClick={() => {
                                            const token = localStorage.getItem(ACCESS_TOKEN_KEY);
                                            const refreshToken = localStorage.getItem(
                                                REFRESH_TOKEN_KEY);
                                            window.open(
                                                `${process.env.REACT_APP_CART_URL}/?${createQueryParams(
                                                    {
                                                        token,
                                                        refreshToken,
                                                    })}`, '_blank');
                                        }}>
                                            <ShoppingCart
                                                className={`${classes.icons} ${isLoggingIn && classes.mr10}`}
                                            />
                                        </IconButton>


                                        {!userInfo?.user_id ?
                                            <>
                                                <IconButton color={'inherit'}
                                                            onClick={this.showAppList}>
                                                    <Apps className={classes.icons}/>
                                                </IconButton>
                                                <AppListPopup
                                                    anchorEl={this.state.appListPopupAnchorEl}
                                                    onClose={this.closeAppListPopup}
                                                />
                                                <LoginRegisterButtons/>
                                            </>
                                            :
                                            <NavigationGlobal
                                                user={userInfo}
                                                db={db}
                                                service={'myaccount'}
                                                switchAccount={this.onLogout}
                                                onClickLogOut={this.onLogout}
                                                onClickAccount={this.onClickAccount}
                                                intl={{}}
                                                hiddenApp={true}
                                                notification={false}
                                                storage={storage}
                                                onUpdateAvatar={async (url) => {
                                                    await changeAccountInfo({avatar: url});
                                                    await getUserInfoAsync(true);
                                                    localStorage.setItem(USER_INFO_CHANGED_KEY, 1);
                                                    setUserInfo({...userInfo, avatar: url})
                                                }
                                                }
                                            />

                                        }
                                    </Toolbar>
                                </AppBar>
                            </div>
                        );
                    }}
            </UserContext.Consumer>
        );
    }
}

MenuAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuAppBar);
