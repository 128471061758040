import {IS_SOCIAL_SIGN_IN, LOGIN_KEY, REDIRECT_URL_KEY, REGISTER_KEY, USER_INFO_KEY} from "../apis/constants";
import store from "store";

export default () => {
    store.remove(REGISTER_KEY);
    store.remove(LOGIN_KEY);
    store.remove(USER_INFO_KEY);
    store.remove(REDIRECT_URL_KEY);
    store.remove('course_id');
    store.remove('service_id');
    store.remove('language');
    localStorage.removeItem(IS_SOCIAL_SIGN_IN)
}
