import React, {useEffect, useState} from 'react';
import qs from "query-string";
import axios from "axios";
import {API_PREFIX, METAMORS_PREFIX, USER_INFO} from "../../apis/new_urls";
import store from "store";
import {
    ACCESS_TOKEN_KEY,
    COOKIE_GENIAM_REFRESH_TOKEN_KEY,
    IS_SOCIAL_SIGN_IN,
    REDIRECT_URL_KEY,
    USER_INFO_KEY
} from "../../apis/constants";
import {setCookie} from "../../common/cookies";
import _ from "lodash";
import {LinearProgress} from '@material-ui/core'
import {toast} from "react-toastify";
import {socialLogin} from "./SocialLogin";
import MailMagazineDialog from "../Account/MailMagazineDialog";

function AuthenticationCode(props) {

    const [openMailMagazine, setOpenMailMagazin] = useState(false)
    useEffect(() => {
        const query = qs.parse(window.location.search)
        if (query?.code) {
            //
            verifyCode(query.code)
        } else {
            if (query.error_description && query.error_description.includes("Already found an entry for username")) {
                // first sync social email to geniam user email -> need login again to get data
                let provider = ""
                if (query.error_description.includes("Google_")) {
                    provider = "Google"
                } else {
                    provider = "Facebook"
                }
                toast.success(`${provider} login Already found an email. Login again to get data ...`)
                setTimeout(() => {
                    socialLogin(provider)
                }, 2000)
            } else {
                window.location.href = '/login'
            }

        }
    }, [])

    const verifyCode = async (code) => {
        try {
            const redirect_uri = process.env.REACT_APP_COGNITO_REDIRECT_URL
            const {data} = await axios.post(`${API_PREFIX}/signin-social`, {
                code,
                redirect_uri
            })
            // removeAllTokens()
            localStorage.setItem(ACCESS_TOKEN_KEY, data.access_token)
            localStorage.setItem(IS_SOCIAL_SIGN_IN, "true")
            setCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY, data.refresh_token)

            const res = await axios.get(USER_INFO)
            store.set(USER_INFO_KEY, res.data)

            // check new user sign up to open mail magazine
            if (data.isNew) {
                setOpenMailMagazin(true)
                return
            }
            const redirectTo = store.get(REDIRECT_URL_KEY)
            if (!redirectTo) {
                window.location.href = '/'
                return
            }
            if (_.includes(redirectTo, METAMORS_PREFIX) || _.includes(redirectTo, 'account.metamors.com')) {
                // const {data} = await axios.get(USER_INFO)
                return window.location.href = `${METAMORS_PREFIX}/?uuid=${res.data.user_id}`;
            } else if (!_.includes(redirectTo, METAMORS_PREFIX)) {
                // const userInfo = store.get(USER_INFO_KEY)
                // if (!userInfo) {
                //     const {data} = await axios.get(USER_INFO)
                //     store.set(USER_INFO_KEY, data)
                // }
                let url = redirectTo
                if (url.includes("?")) {
                    url = url + "&" + qs.stringify({
                        token: data.access_token,
                        refreshToken: data.refresh_token
                    }, {sort: false})
                } else {
                    url = url + "?" + qs.stringify({
                        token: data.access_token,
                        refreshToken: data.refresh_token
                    }, {sort: false})
                }
                // console.log(url);
                return window.location.href = url

            } else {
                window.location.href = '/login/confirm';
            }
        } catch (e) {
            toast.error(e.toString())
            window.location.href = '/login'
        } finally {
        }
    }
    return (
        <div>
            <LinearProgress/>
            <MailMagazineDialog open={openMailMagazine} setOpen={setOpenMailMagazin}/>
        </div>
    );
}

export default AuthenticationCode;
